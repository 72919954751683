import { QueryClient } from '@tanstack/react-query'
import { getExplorerUrl } from 'config/chains'
import { TOAST_AUTO_CLOSE_TIME } from 'config/ui'
import { SubaccountContextProvider } from 'context/SubaccountContext/SubaccountContext'
import { WebsocketContextProvider } from 'context/WebsocketContext/WebsocketContextProvider'
import { useMarketsInfo } from 'domain/synthetics/markets/useMarketsInfo'
import { useChainId } from 'gmx/lib/chains'
import { useEthersSigner } from 'hooks/contracts/useEthersSigner'
import usePythPricesFetcher from 'hooks/gmx/usePyth/usePythPricesFetcher'
import { PendingTransaction } from 'hooks/usePaymaster'
import { helperToast } from 'gmx/lib/helperToast'
import { ThemeProvider } from 'next-themes'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import SuccessIcon from 'public/icons/toast/success.svg'
import { useEffect, useState } from 'react'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { THEME_KEY } from 'utils/constants'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import InitiateGlobalStoresProvider from './InitiateStoreProvider'

const Layout = dynamic(() => import('../components/Layout'))
const SyntheticsEventsProvider = dynamic(
  () => import('context/SyntheticsEvents/SyntheticsEventsProvider'),
)
const MangoProvider = dynamic(() => import('@components/MangoProvider'))

export const queryClient = new QueryClient()

const CustomIcon = (props: any) => {
  switch (props.type) {
    case 'success':
      return <SuccessIcon className="success-icon" />
    case 'error':
      return (
        <Image src="/icons/toast/error.svg" width={16} height={16} alt={''} />
      )
    default:
      return undefined
  }
}

function FullApp(p: any) {
  const signer = useEthersSigner()
  const { chainId } = useChainId()

  const [pendingTxns, setPendingTxns] = useState<PendingTransaction[]>([])

  useEffect(() => {
    if (!signer) {
      return
    }
    const checkPendingTxns = async () => {
      const updatedPendingTxns: PendingTransaction[] = []
      for (const pendingTxn of pendingTxns) {
        const receipt = await signer.provider.getTransactionReceipt(
          pendingTxn.hash,
        )
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + 'tx/' + pendingTxn.hash
            helperToast.error(
              <div>
                Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                <br />
              </div>,
            )
          }

          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + 'tx/' + pendingTxn.hash
            helperToast.success(
              <div>
                {pendingTxn.message}{' '}
                <ExternalLink href={txUrl}>View</ExternalLink>
                <br />
                {pendingTxn.messageDetails && <br />}
                {pendingTxn.messageDetails}
              </div>,
            )
          }
          continue
        }
        updatedPendingTxns.push(pendingTxn)
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns)
      }
    }

    const interval = setInterval(() => {
      checkPendingTxns()
    }, 2 * 1000)
    return () => clearInterval(interval)
  }, [signer, pendingTxns, chainId])

  usePythPricesFetcher(chainId)
  // fetch markets info
  useMarketsInfo()

  return (
    <WebsocketContextProvider>
      <SyntheticsEventsProvider>
        <SubaccountContextProvider>
          <InitiateGlobalStoresProvider />
          <MangoProvider />
          <ToastContainer
            limit={5}
            transition={Zoom}
            position="bottom-right"
            autoClose={TOAST_AUTO_CLOSE_TIME}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={false}
            draggable={false}
            icon={CustomIcon}
            pauseOnHover
          />
          <ThemeProvider defaultTheme="Patek" storageKey={THEME_KEY}>
            <PageTitle />
            <Layout>
              <p.Component {...p.pageProps} />
            </Layout>
          </ThemeProvider>
        </SubaccountContextProvider>
      </SyntheticsEventsProvider>
    </WebsocketContextProvider>
  )
}

const PageTitle = () => {
  return (
    <Head>
      <title>RFX</title>
    </Head>
  )
}

export default FullApp
