import tradeboxStore from '@store/tradeboxStore'
import DataStore from 'abis/DataStore.json'
import {
  ARBITRUM,
  AVALANCHE,
  AVALANCHE_FUJI,
  NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR,
  ZKSYNC,
  ZKSYNC_SEPOLIA,
} from 'config/chains'
import { getContract } from 'config/contracts'
import {
  SUBACCOUNT_ORDER_ACTION,
  maxAllowedSubaccountActionCountKey,
  subaccountActionCountKey,
  subaccountAutoTopUpAmountKey,
  subaccountListKey,
} from 'config/dataStore'
import { getSubaccountConfigKey } from 'config/localStorage'
import { getNativeToken, getWrappedToken } from 'config/tokens'
import cryptoJs from 'crypto-js'

import { useTransactionPending } from 'domain/synthetics/common/useTransactionReceipt'
import {
  estimateExecuteIncreaseOrderGasLimit,
  getExecutionFee,
  useGasLimits,
  useGasPrice,
} from 'domain/synthetics/fees'
import { estimateOrderOraclePriceCount } from 'domain/synthetics/fees/utils/estimateOraclePriceCount'
import { STRING_FOR_SIGNING } from 'domain/synthetics/subaccount/constants'
import { SubaccountSerializedConfig } from 'domain/synthetics/subaccount/types'
import {
  useTokenBalances,
  useTokensDataRequest,
} from 'domain/synthetics/tokens'
import { BigNumber, ethers } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { useLocalStorageSerializeKey } from 'gmx/lib/localStorage'
import { useMulticall } from 'gmx/lib/multicall'
import { applyFactor } from 'gmx/lib/numbers'
import { getByKey } from 'gmx/lib/objects'
import { getProvider } from 'gmx/lib/rpc'
import useWallet from 'gmx/lib/wallets/useWallet'
import { uniqBy, uniqueId } from 'lodash'
import {
  Context,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createContext, useContextSelector } from 'use-context-selector'
import { privateKeyToAccount } from 'viem/accounts'

export type Subaccount = ReturnType<typeof useSubaccount>

export type SubaccountNotificationState =
  | 'generating'
  | 'activating'
  | 'activated'
  | 'activationFailed'
  | 'generationFailed'
  | 'deactivating'
  | 'deactivated'
  | 'deactivationFailed'
  | 'none'

export type SubaccountContext = {
  activeTx: string | null
  defaultExecutionFee: BigNumber | null
  defaultNetworkFee: BigNumber | null
  contractData: {
    isSubaccountActive: boolean
    maxAllowedActions: BigNumber
    currentActionsCount: BigNumber
    currentAutoTopUpAmount: BigNumber
  } | null
  subaccount: SubaccountSerializedConfig | null
  subaccounts: SubaccountSerializedConfig[]

  modalOpen: boolean
  notificationState: SubaccountNotificationState
  setConfig: Dispatch<SetStateAction<SubaccountSerializedConfig[] | undefined>>
  clearSubaccount: () => void
  generateSubaccount: () => Promise<string | null>
  setActiveTx: (tx: string | null) => void
  setModalOpen: (v: boolean) => void
  setNotificationState: (state: SubaccountNotificationState) => void
  refetchContractData: () => void
  setSelectedSubaccount: Dispatch<
    SetStateAction<SubaccountSerializedConfig | undefined>
  >
}

const context = createContext<SubaccountContext | null>(null)

// TODO gmxer: refactor this in chains.ts so that new networks are required
function getFactorByChainId(chainId: number) {
  switch (chainId) {
    case ARBITRUM:
    case AVALANCHE_FUJI:
    case AVALANCHE:
    case ZKSYNC_SEPOLIA:
    case ZKSYNC:
      return NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR[chainId]

    default:
      throw new Error(`Unsupported chainId ${chainId}`)
  }
}

export function SubaccountContextProvider({ children }: any) {
  const [modalOpen, setModalOpen] = useState(false)
  const [notificationState, setNotificationState] =
    useState<SubaccountNotificationState>('none')

  const { signer, account } = useWallet()
  const { chainId } = useChainId()
  const [config, setConfig] = useLocalStorageSerializeKey<
    SubaccountSerializedConfig[]
  >(getSubaccountConfigKey(chainId, account), [])
  const [selectedSubaccount, setSelectedSubaccount] =
    useLocalStorageSerializeKey<SubaccountSerializedConfig | undefined>(
      [chainId, account, 'selectedSubaccount'],
      undefined,
    )

  const { gasPrice } = useGasPrice(chainId)
  const { gasLimits } = useGasLimits(chainId)
  const { tokensData } = useTokensDataRequest(chainId)

  // fee that is used as a approx basis to calculate
  // costs of subaccount actions
  const [defaultExecutionFee, defaultNetworkFee] = useMemo(() => {
    if (!gasLimits || !tokensData || !gasPrice) {
      return [null, null]
    }

    const approxNetworkGasLimit = applyFactor(
      applyFactor(
        gasLimits.estimatedGasFeeBaseAmount,
        gasLimits.estimatedFeeMultiplierFactor,
      ),
      getFactorByChainId(chainId),
    )
      // createOrder is smaller than executeOrder
      // L2 Gas
      ?.add(800_000)

    const networkFee = approxNetworkGasLimit?.mul(gasPrice)

    const gasLimit = estimateExecuteIncreaseOrderGasLimit?.(gasLimits, {
      swapsCount: 1,
    })
    const oraclePriceCount = estimateOrderOraclePriceCount(1)

    const executionFee =
      getExecutionFee?.(
        chainId,
        gasLimits,
        tokensData,
        gasLimit,
        gasPrice,
        oraclePriceCount,
      )?.feeTokenAmount ?? BigNumber.from(0)
    return [executionFee, networkFee]
  }, [chainId, gasLimits, gasPrice, tokensData])

  const generateSubaccount = useCallback(async () => {
    if (!account) {
      throw new Error('Account is not set')
    }

    const signature = await signer?.signMessage(
      STRING_FOR_SIGNING + uniqueId('SUB_ACCOUNT_') + config?.length,
    )

    if (!signature) {
      return null
    }

    const pk = ethers.utils.keccak256(signature)
    const subWallet = new ethers.Wallet(pk)

    const encrypted = cryptoJs.AES.encrypt(pk, account)

    config?.length === 0 &&
      setSelectedSubaccount({
        privateKey: encrypted.toString(),
        address: subWallet.address,
      })

    setConfig(
      uniqBy(
        [
          ...(config as SubaccountSerializedConfig[]),
          {
            privateKey: encrypted.toString(),
            address: subWallet.address,
          },
        ],
        'address',
      ),
    )

    return subWallet.address
  }, [account, setConfig, signer, config?.length])

  const clearSubaccount = useCallback(() => {
    setConfig([])
  }, [setConfig])

  const [activeTx, setActiveTx] = useState<string | null>(null)
  const [contractData, setContractData] = useState<
    SubaccountContext['contractData'] | null
  >(null)
  const isTxPending = useTransactionPending(activeTx)

  const {
    data: fetchedContractData,
    isLoading,
    mutate: refetchContractData,
  } = useMulticall(chainId, 'useSubaccountsFromContracts', {
    key:
      account && selectedSubaccount
        ? [
            account,
            activeTx,
            selectedSubaccount?.address,
            isTxPending ? 'pending' : 'not-pending',
          ]
        : null,
    request: () => {
      return {
        dataStore: {
          contractAddress: getContract(chainId, 'DataStore'),
          abi: DataStore.abi,
          calls: {
            isSubaccountActive: {
              methodName: 'containsAddress',
              params: [
                subaccountListKey(account?.toString() || ''),
                selectedSubaccount?.address,
              ],
            },
            maxAllowedActionsCount: {
              methodName: 'getUint',
              params: [
                maxAllowedSubaccountActionCountKey(
                  account?.toString() || '',
                  selectedSubaccount?.address || '',
                  SUBACCOUNT_ORDER_ACTION,
                ),
              ],
            },
            currentActionsCount: {
              methodName: 'getUint',
              params: [
                subaccountActionCountKey(
                  account?.toString() || '',
                  selectedSubaccount?.address || '',
                  SUBACCOUNT_ORDER_ACTION,
                ),
              ],
            },
            currentAutoTopUpAmount: {
              methodName: 'getUint',
              params: [
                subaccountAutoTopUpAmountKey(
                  account?.toString() || '',
                  selectedSubaccount?.address || '',
                ),
              ],
            },
          },
        },
      }
    },
    parseResponse: (res) => {
      const isSubaccountActive = Boolean(
        res.data.dataStore.isSubaccountActive.returnValues[0],
      )
      const maxAllowedActions = BigNumber.from(
        res.data.dataStore.maxAllowedActionsCount.returnValues[0],
      )
      const currentActionsCount = BigNumber.from(
        res.data.dataStore.currentActionsCount.returnValues[0],
      )
      const currentAutoTopUpAmount = BigNumber.from(
        res.data.dataStore.currentAutoTopUpAmount.returnValues[0],
      )

      return {
        isSubaccountActive,
        maxAllowedActions,
        currentActionsCount,
        currentAutoTopUpAmount,
      }
    },
  })

  useEffect(() => {
    if (isLoading) {
      return
    }

    setContractData(fetchedContractData ?? null)
  }, [fetchedContractData, isLoading])

  const value: SubaccountContext = useMemo(() => {
    return {
      modalOpen,
      setModalOpen,
      defaultExecutionFee,
      defaultNetworkFee,
      subaccount: selectedSubaccount || null,
      contractData: selectedSubaccount && contractData ? contractData : null,
      refetchContractData,
      generateSubaccount,
      clearSubaccount,
      notificationState,
      activeTx,
      setActiveTx,
      setNotificationState,
      setSelectedSubaccount,
      subaccounts: config || [],
      setConfig,
    }
  }, [
    activeTx,
    clearSubaccount,
    config,
    contractData,
    refetchContractData,
    defaultExecutionFee,
    defaultNetworkFee,
    generateSubaccount,
    modalOpen,
    notificationState,
    selectedSubaccount,
  ])

  return <context.Provider value={value}>{children}</context.Provider>
}

export function useSubaccountSelector<Selected>(
  selector: (s: SubaccountContext) => Selected,
) {
  return useContextSelector(
    context as Context<SubaccountContext>,
    selector,
  ) as Selected
}

export function useSubaccountModalOpen() {
  return [
    useSubaccountSelector((s) => s.modalOpen),
    useSubaccountSelector((s) => s.setModalOpen),
  ] as const
}

export function useSubaccountGenerateSubaccount() {
  return useSubaccountSelector((s) => s.generateSubaccount)
}

export function useSubaccountSelectSubaccount() {
  const selectedAccount = useSubaccountSelector((s) => s)
  return (address: string) => {
    selectedAccount.setSelectedSubaccount(
      selectedAccount.subaccounts.find((item) => item.address === address),
    )

    return selectedAccount.subaccounts.find((item) => item.address === address)
  }
}

export function useSubaccountDeleteSubaccount() {
  const selectedAccount = useSubaccountSelector((s) => s)
  return (address: string) => {
    if (selectedAccount.subaccount?.address === address) {
      selectedAccount.setSelectedSubaccount(
        selectedAccount.subaccounts.find((item) => item.address !== address),
      )
    }

    selectedAccount.setConfig(
      selectedAccount.subaccounts.filter((item) => item.address !== address),
    )
  }
}

export function useSubaccountGetSubaccounts() {
  return useSubaccountSelector((s) => s.subaccounts)
}

export function useSubaccountState() {
  return useSubaccountSelector((s) => s)
}

export function useSubaccountAddress() {
  return useSubaccountSelector((s) => {
    return s?.subaccount?.address ?? null
  })
}

function useSubaccountPrivateKey() {
  const encryptedString = useSubaccountSelector(
    (s) => s.subaccount?.privateKey ?? null,
  )
  const { account } = useWallet()
  return useMemo(() => {
    if (!account || !encryptedString) {
      return null
    }

    // race condition when switching accounts:
    // account is already another address
    // while the encryptedString is still from the previous account
    try {
      return cryptoJs.AES.decrypt(encryptedString, account).toString(
        cryptoJs.enc.Utf8,
      )
    } catch (e) {
      return null
    }
  }, [account, encryptedString])
}

export function useIsSubaccountActive() {
  const pkAvailable = useSubaccountPrivateKey() !== null
  return (
    useSubaccountSelector((s) => s.contractData?.isSubaccountActive ?? false) &&
    pkAvailable
  )
}

export function useSubaccountDefaultExecutionFee() {
  return (
    useSubaccountSelector((s) => s.defaultExecutionFee) ?? BigNumber.from(0)
  )
}

function useSubaccountDefaultNetworkFee() {
  return useSubaccountSelector((s) => s.defaultNetworkFee) ?? BigNumber.from(0)
}

export function useSubaccount(
  requiredBalance: BigNumber | null,
  requiredActions = 1,
) {
  const address = useSubaccountAddress()
  const active = useIsSubaccountActive()
  const privateKey = useSubaccountPrivateKey()
  const { chainId } = useChainId()
  const defaultExecutionFee = useSubaccountDefaultExecutionFee()
  const insufficientFunds = useSubaccountInsufficientFunds(
    requiredBalance ?? defaultExecutionFee,
  )

  const { remaining } = useSubaccountActionCounts()
  const useOneClickTrading = tradeboxStore((store) => store.useOneClickTrading)

  return useMemo(() => {
    if (
      !address ||
      !active ||
      !privateKey ||
      insufficientFunds ||
      remaining?.lt(Math.max(1, requiredActions)) ||
      !useOneClickTrading
    ) {
      return null
    }

    const provider: any = getProvider(undefined, chainId)
    const wallet = new ethers.Wallet(privateKey, provider)
    const signer = wallet.connect(provider)
    const account = privateKeyToAccount(privateKey as `0x${string}`)
    return {
      address,
      active,
      signer,
      wallet,
      account,
    }
  }, [
    address,
    active,
    privateKey,
    insufficientFunds,
    remaining,
    requiredActions,
    chainId,
  ])
}

export function useSubaccountInsufficientFunds(
  requiredBalance: BigNumber | undefined | null,
) {
  const { chainId } = useChainId()
  const subaccountAddress = useSubaccountAddress()
  const subBalances = useTokenBalances(chainId, subaccountAddress ?? undefined)
  const nativeToken = useMemo(() => getNativeToken(chainId), [chainId])
  const nativeTokenBalance = getByKey(
    subBalances.balancesData,
    nativeToken.address,
  )
  const isSubaccountActive = useIsSubaccountActive()
  const defaultExecutionFee = useSubaccountDefaultExecutionFee()
  const networkFee = useSubaccountDefaultNetworkFee()
  const required = (
    requiredBalance ??
    defaultExecutionFee ??
    BigNumber.from(0)
  ).add(networkFee)

  if (!isSubaccountActive) {
    return false
  }
  if (!nativeTokenBalance) {
    return false
  }

  return required.gt(nativeTokenBalance)
}

export function useMainAccountInsufficientFunds(
  requiredBalance: BigNumber | undefined | null,
) {
  const { chainId } = useChainId()
  const { account: address } = useWallet()
  const balances = useTokenBalances(chainId, address)
  const wrappedToken = useMemo(() => getWrappedToken(chainId), [chainId])
  const wntBalance = getByKey(balances.balancesData, wrappedToken.address)
  const isSubaccountActive = useIsSubaccountActive()
  const networkFee = useSubaccountDefaultNetworkFee()
  const defaultExecutionFee = useSubaccountDefaultExecutionFee()
  const required = (requiredBalance ?? defaultExecutionFee).add(networkFee)

  if (!isSubaccountActive) {
    return false
  }
  if (!wntBalance) {
    return false
  }

  return required.gt(wntBalance)
}

export function useSubaccountActionCounts() {
  const current = useSubaccountSelector(
    (s) => s.contractData?.currentActionsCount ?? null,
  )
  const max = useSubaccountSelector(
    (s) => s.contractData?.maxAllowedActions ?? null,
  )
  const remaining = max?.sub(current ?? 0) ?? null

  return {
    current,
    max,
    remaining,
  }
}

export function useSubaccountPendingTx() {
  return [
    useSubaccountSelector((s) => s.activeTx),
    useSubaccountSelector((s) => s.setActiveTx),
  ] as const
}

export function useIsLastSubaccountAction(requiredActions = 1) {
  const { remaining } = useSubaccountActionCounts()
  return remaining?.eq(Math.max(requiredActions, 1)) ?? false
}

export function useSubaccountCancelOrdersDetailsMessage(
  overridedRequiredBalance: BigNumber | undefined,
  actionCount: number,
) {
  const defaultRequiredBalance = useSubaccountDefaultExecutionFee()
  const requiredBalance = overridedRequiredBalance ?? defaultRequiredBalance
  const isLastAction = useIsLastSubaccountAction(actionCount)
  const subaccountInsufficientFunds =
    useSubaccountInsufficientFunds(requiredBalance)
  const [, setOpenSubaccountModal] = useSubaccountModalOpen()
  const refetchContractData = useSubaccountRefetchContractData()
  const handleOpenSubaccountModal = useCallback(() => {
    setOpenSubaccountModal(true)
    refetchContractData()
  }, [setOpenSubaccountModal, refetchContractData])

  return useMemo(() => {
    if (isLastAction) {
      return (
        <>
          Max Action Count Reached.{' '}
          <span
            onClick={handleOpenSubaccountModal}
            className="inline-flex cursor-pointer text-th-fgd-2 underline hover:text-th-fgd-1"
          >
            Click here
          </span>{' '}
          to update.
        </>
      )
    } else if (subaccountInsufficientFunds) {
      return (
        <>
          There are insufficient funds in your Subaccount for One-Click Trading.{' '}
          <span
            onClick={handleOpenSubaccountModal}
            className="inline-flex cursor-pointer text-th-fgd-2 underline hover:text-th-fgd-1"
          >
            Click here
          </span>{' '}
          to top-up.
        </>
      )
    }

    return null
  }, [isLastAction, handleOpenSubaccountModal, subaccountInsufficientFunds])
}

export function useSubaccountNotificationState() {
  return [
    useSubaccountSelector((s) => s.notificationState),
    useSubaccountSelector((s) => s.setNotificationState),
  ] as const
}

export function useSubaccountRefetchContractData() {
  return useSubaccountSelector((s) => s.refetchContractData)
}
