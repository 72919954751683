import {
  useInitiateMarketsStore,
  useInitiateMarketsInfoData,
} from '@store/marketsStore'
import { useInitiatePositionsInfo } from '@store/positionsStore'

function InitiateGlobalStoresProvider() {
  // initiate store hooks
  useInitiateMarketsStore()
  useInitiateMarketsInfoData()
  useInitiatePositionsInfo()

  return null
}

export default InitiateGlobalStoresProvider
