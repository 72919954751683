import { EvmPriceServiceConnection, PriceFeed } from '@pythnetwork/pyth-evm-js'
import commonStore from '@store/commonStore'
import priceStore from '@store/priceStore'
import { IS_VERBOSE } from 'config/development'
import useActiveChainTokens from 'hooks/useActiveChainTokens'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import useSWR from 'swr'
import {
  getPriceFeedTokenInfo,
  getPriceFromPriceFeed,
  tokenPythIdMapping,
} from './utils'

export const USD_DECIMALS = 30

const onPriceUpdate = (priceFeed: PriceFeed) => {
  const setTokenPrice = priceStore.getState().setTokenPrice
  try {
    const price = getPriceFromPriceFeed(priceFeed)
    const pythId = '0x' + priceFeed.id

    setTokenPrice(pythId, {
      maxPrice: price,
      minPrice: price,
      ts: new Date().getTime(),
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.log('🚀 ~ handleIncomingMessage ~ err:', err)
  }
}

const usePythPricesFetcher = (chainId: number, onWsError?: () => void) => {
  const connectionRef = useRef<EvmPriceServiceConnection>()

  const { pathname } = useRouter()

  // const pythIdsForWS = priceStore((store) => store.pythIdsForWS)
  const setIsWSBlocked = commonStore((store) => store.setIsWSBlocked)
  const setPricesUsingApi = commonStore((store) => store.setPricesUsingApi)
  const isWSBlocked = commonStore((store) => store.isWSBlocked)
  const [isWsInitialized, setIsWsInitialized] = useState(false)

  const tokensList = useActiveChainTokens()

  const pythIdsForWS = useMemo(() => {
    const tokenPythIdList = tokensList
      ?.map((token) => token.pythId)
      .filter(Boolean)
    return (tokenPythIdList as string[]) || []
  }, [tokensList])

  const handleWsError = (err: Error) => {
    if (err === undefined) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Failed to initialize Pyth WS', err)
      // setIsWsInitialized()
      setIsWSBlocked(true)
    } else {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS Err:', err)
    }
    onWsError?.()
  }

  const initializeWs = async () => {
    try {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS: Starting WS')
      const connection = new EvmPriceServiceConnection(
        'https://rfx.liquify.com/api=4673860/hermes/ws',
        { verbose: true },
      )
      connection.onWsError = handleWsError
      connectionRef.current = connection
      await connection.startWebSocket()

      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS: Started WS Successfully')
      setIsWsInitialized(true)
      setIsWSBlocked(false)
    } catch (err) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS: Failed to start WS', err)
      setIsWSBlocked(true)
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (!connectionRef.current?.wsClient) {
      initializeWs()
    }
  }, [])

  useEffect(() => {
    if (isWsInitialized && !!pythIdsForWS.length && connectionRef.current) {
      IS_VERBOSE &&
        // eslint-disable-next-line no-console
        console.log(
          'Pyth WS: Subscribing to the price feed for the following -',
          pythIdsForWS,
        )
      connectionRef.current.subscribePriceFeedUpdates(
        pythIdsForWS,
        onPriceUpdate,
      )

      return () => {
        IS_VERBOSE &&
          // eslint-disable-next-line no-console
          console.log(
            'Pyth WS: Unsubscribing from the price feed for the following -',
            pythIdsForWS,
          )

        connectionRef.current?.unsubscribePriceFeedUpdates(
          pythIdsForWS,
          onPriceUpdate,
        )
      }
    }
  }, [pythIdsForWS, isWsInitialized])

  useSWR(
    ['galeto-oracle-price', tokenPythIdMapping(chainId), chainId],
    async ([, mapPythId, chainId]) => {
      const connection = new EvmPriceServiceConnection(
        'https://rfx.liquify.com/api=4673860/hermes/',
      )
      const latestFeed = (await connection.getLatestPriceFeeds(
        Object.keys(mapPythId),
      )) as PriceFeed[]
      let priceInfo = {}
      latestFeed.forEach((result) => {
        const tokenPriceInfo = getPriceFeedTokenInfo({
          _tokenPythIdMapping: tokenPythIdMapping(chainId),
          tokenId: result.id,
          priceFeed: result,
          chainId,
        })

        priceInfo = {
          ...tokenPriceInfo,
          ...priceInfo,
        }
      })

      setPricesUsingApi(priceInfo)

      return {
        priceInfo,
        updatedAt: latestFeed[0].getPriceUnchecked().publishTime,
      }
    },
    {
      refreshInterval: isWSBlocked || pathname === '/earn' ? 4000 : undefined,
      revalidateOnFocus: isWSBlocked,
      keepPreviousData: true,
    },
  )

  return {}
}

export default usePythPricesFetcher
